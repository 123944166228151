import React, { useContext, useEffect, useState, useRef } from "react";
import { Table, Modal, message, Row, Space, Input, Button } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { PageTitle } from "../../PageTitle";
import axiosInstance from "../../axiosInstance";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../globalContext";
import Highlighter from "react-highlight-words";
import wollet from '../../images/wallet.32eb69bea0c4c697ebf9.png';
import logo from '../../images/hemadesiludo.png';
import face from '../../images/face8.jpg';


const FastParityBat = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleToggleClick = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  let defaultDate = new Date();
  const user = useContext(UserContext);
  const [allProfit, setAllProfit] = useState();

  const [PaymentData, setPaymentData] = useState([]);
  const [readSnfObj, setReadSnfObj] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionDate, setTransactionDate] = useState(defaultDate);

  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);

  const pageLoad = () => {
    getParity();
    onSetDate();
  };

  const onSetDate = () => {
    setTransactionDate(new Date());
  };


  const getParity = () => {
    axiosInstance.post(`/fastParity/runing2`).then(response => {
      console.log("parity data", response.data.data)
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.gameDate) - new Date(a.gameDate),
      );
      setPaymentData(sortedData);
    });
  };


  const handleLogout = () => {
    localStorage.clear();
    window.location.href = `/`;
  };
  const getAllProfit = () => {
    axiosInstance.post(`/profit/report`).then(response => {
      if (response.data.data.length > 0) {
        setAllProfit(...response.data.data);
      } else {
        setAllProfit({ amount: 0 });
      }
    });
  };






  const [searchQuery, setSearchQuery] = useState('');

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter PaymentData based on search query
  var filteredData = PaymentData.filter(data => {
    const user = data.user;
    return (
      data.id.toString().includes(searchQuery) ||
      (user && user.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user && user.mobile.toLowerCase().includes(searchQuery.toLowerCase())) ||
      data.amount.toString().includes(searchQuery) ||
      moment(data.gameDate).format('DD-MM-yyyy').includes(searchQuery) ||
      data.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      data.color.toLowerCase().includes(searchQuery.toLowerCase()) ||
      data.number.toLowerCase().includes(searchQuery.toLowerCase()) ||
      data.period.toLowerCase().includes(searchQuery.toLowerCase())||
      data.result.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });









  const [totalPage, setTotalPage] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // You can change this value to set the number of items per page

  // Calculate the indices for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  filteredData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const pageNumbers = [];

  // Handle page change
  const handlePageChange = (pageNumber) => {
    if ((pageNumber <= pageNumbers.length) && pageNumber > 0) {
      setCurrentPage(pageNumber);
    }
  };

  // Render pagination controls
  const renderPagination = () => {
    const totalPages = Math.ceil(PaymentData.length / itemsPerPage);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    //  return (
    //   <>


    //       {pageNumbers.map(number => (
    //         <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`} class="page-item">
    //           <a onClick={() => handlePageChange(number)} class="page-link">
    //             {number}
    //           </a>

    //         </li>
    //       ))}

    //   </>
    // );
  };


  return (
    <div>




      <div>
        <div className={` ${isSidebarVisible ? 'sidebar-icon-only' : ''}`}>
          {/* //nav top header start */}
          <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
              <a class="navbar-brand brand-logo" href="javascript:void(0)">
                <img src={logo} alt="logo" class="logo-dark" />
              </a>
              <a class="navbar-brand brand-logo-mini" href="javascript:void(0)"></a>
              <button
                class="navbar-toggler navbar-toggler align-self-center"
                type="button"
                data-toggle="minimize"
                onClick={handleToggleClick}>
                <span class="icon-menu"></span>
              </button>
            </div>
            <div class="navbar-menu-wrapper d-flex align-items-center">
              <h5 class="mb-0 font-weight-medium d-none d-lg-flex">
                Welcome Your dashboard!
              </h5>
              <ul class="navbar-nav navbar-nav-right">
                <li>
                  <div>
                    <a
                      class="text-decoration-none text-white "
                      href="javascriptvoid:(0)">
                      <div class="py-1 bg-white border px-2 text-dark d-flex align-items-center rounded-2">
                        <img className="wllet" src={wollet} alt="" srcset="" />
                        {allProfit === undefined && <strong>0</strong>}
                        {allProfit !== undefined && (
                          <strong>{allProfit.amount}</strong>
                        )}
                      </div>
                    </a>
                  </div>
                </li>

                <li class="nav-item dropdown d-none d-xl-inline-flex user-dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    id="UserDropdown"
                    href="#"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <img
                      class="img-xs rounded-circle ms-2"
                      src={face}
                      alt="Profile image"
                    />
                    <span class="font-weight-normal"> Admin </span>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right navbar-dropdown"
                    aria-labelledby="UserDropdown">
                    <div class="dropdown-header text-center">
                      <img
                        class="img-md rounded-circle"
                        src={face}
                        alt="Profile image"
                      />
                      <p class="mb-1 mt-3">Admin</p>
                      <p class="font-weight-light text-muted mb-0">
                        Admin@gmail.com
                      </p>
                    </div>

                    <a class="dropdown-item" onClick={() => handleLogout()}>
                      <i class="dropdown-item-icon icon-power text-primary"></i>
                      Log Out
                    </a>
                  </div>
                </li>
              </ul>
              <button
                class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                type="button"
                data-toggle="offcanvas"
                onClick={handleToggleClick}>
                <span class="icon-menu"></span>
              </button>
            </div>
          </nav>
          {/* //nav top header end */}

          <div class="container-scroller">
            <div class="container-fluid page-body-wrapper">
              {/* //sidebar start */}
              <nav
            class={`sidebar sidebar-offcanvas ${
              isSidebarVisible ? 'sidebar sidebar-offcanvas active' : ''
            }`}
            id="sidebar">
            <div
              class=""
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical">
              <ul class="nav">
                {/* <li class="nav-item navbar-brand-mini-wrapper">
                  <a
                    class="nav-link navbar-brand brand-logo-mini"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true">
                    <i class="icon-screen-desktop menu-icon"></i>
                  </a>
                </li> */}
                <li class="nav-item nav-profile">
                  <a href="javascript:void(0)" class="nav-link ">
                    <div class="profile-image">
                      <img
                        class="img-xs rounded-circle"
                        src={face}
                        alt="profile image"
                      />
                      <div class="dot-indicator bg-success"></div>
                    </div>
                    <div class="text-wrapper">
                      <p class="profile-name">Admin</p>
                      <p class="designation">Administrator</p>
                    </div>
                    <div class="icon-container mt-3">
                      <i class="icon-bubbles"></i>
                      <div class="dot-indicator bg-danger"></div>
                    </div>
                  </a>
                </li>

                <li class="nav-item active">
                  <a
                    class="nav-link active"
                  
                     href="#/HomeScreen">
                    <span class="menu-title">Dashboard</span>
                    <i class="icon-screen-desktop menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/ARecharge"
                   >
                    <span class="menu-title">Recharge Request</span>
                    <i class="icon-energy menu-icon"></i>

                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/AWithdraw"
                   >
                    <span class="menu-title">Withdraw Request</span>
                    <i class="icon-wallet menu-icon"></i>

                    
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                 
                   
                    href="#/member"
                    >
                    <span class="menu-title">Active User</span>
                    <i class="icon-user menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#/deletedMember">
                    <span class="menu-title" >Deleted User</span>
                    <i class="icon-user-unfollow menu-icon"></i>

                  </a>
                </li>
             
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/fastParityBat"

                   >
                    <span class="menu-title">Fast Parity Bat</span>
                    <i class="icon-book-open menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/parityBat"
                   >
                    <span class="menu-title">Parity Bat</span>
                    <i class="icon-wallet menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/fastParityResult">
                   
                    <span class="menu-title">Fast Parity Result</span>
                    <i class="icon-trophy menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/parityResult"
                   >
                    <span class="menu-title">Parity Result</span>
                    <i class="icon-grid menu-icon"></i>
                  </a>
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/diceBat"

                   >
                    <span class="menu-title">Dice Bat</span>
                    <i class="icon-book-open menu-icon"></i>
                  </a>
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/diceResult"

                   >
                    <span class="menu-title">Dice Result</span>
                    <i class="icon-book-open menu-icon"></i>
                  </a>
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/aviatorBat"

                   >
                    <span class="menu-title">Aviator Bat</span>
                    <i class="icon-book-open menu-icon"></i>
                  </a>
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/aviatorResult"

                   >
                    <span class="menu-title">Aviator Result</span>
                    <i class="icon-book-open menu-icon"></i>
                  </a>
                </li>




                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/profittable"
                   >
                    <span class="menu-title">Profit Table</span>
                    <i class="icon-shield menu-icon"></i>

                  </a>
                </li>
           
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/upi" >
                    <span class="menu-title">UPI Change</span>
                    <i class="icon-disc menu-icon"></i>

                  </a>
                </li>
             
          
              
     
              </ul>
            </div>
          </nav>
              {/* //sidebar end */}

              <div class="main-panel">
                <div class="content-wrapper">
                  <div>
                    <div class="tab-content" id="v-pills-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="v-pills-home"
                        role="tabpanel"
                        aria-labelledby="v-pills-home-tab">
                        {/* your content */}

                        <div className="container-fluid">
                          <div className="text-center main-heading">
                            <div class="row">
                              <div class="col-md-12 grid-margin stretch-card ">
                                <div class="card">
                                  <div class="card-body">
                                    <div class="d-sm-flex align-items-center mb-4">
                                      <h4 class="card-title mb-sm-0">
                                      Fast Parity All Bat
                                      </h4>
                                      <span

                                        class="text-dark ms-auto mb-3 mb-sm-0">
                                        {/* Search Input */}
                                        <input
                                          type="text"
                                          placeholder="Search..."
                                          value={searchQuery}
                                          className='searchadd'
                                          onChange={handleSearchChange}
                                        />
                                      </span>
                                    </div>

                                    <div class="table-responsive border rounded p-1">
                                      <div>


                                        <table className="table">
                                          <thead>
                                            <tr>
                                              <th className="font-weight-bold">User</th>

                                              <th className="font-weight-bold">Period</th>
                                              <th className="font-weight-bold">Color/No.</th>
                                              <th className="font-weight-bold">Amount</th>

                                              <th className="font-weight-bold">Result</th>
                                              <th className="font-weight-bold">Status</th>
                                            </tr>
                                          </thead>
                                          {filteredData && filteredData[0] && filteredData.map(data => {
                                            const user = data.user;
                                            return (
                                              <tbody key={data.id}>
                                                <tr>
                                                
                                                  {data.member !== null ? (
                                                    <td>
                                                      <b>{data.user.name}</b>
                                                      <br />
                                                      <b>{data.user.mobile}</b>
                                                    </td>
                                                  ) : (
                                                    <td></td>
                                                  )}
                                                   <td>{data.period}</td>
                                                  <td>
                                                    {data.number == "color" && <div>{data.color}</div>}
                                                    {data.color == "number" && <div>{data.number}</div>}
                                                  </td>
                                                  <td>{data.amount}</td>
                                                 
                                                   <td>{data.result}</td>
                                                  <td>{data.status}</td>

                                                </tr>
                                              </tbody>
                                            );
                                          })}
                                        </table>
                                      </div>
                                    </div>
                                    <div class="d-flex mt-4 flex-wrap align-items-center">
                                      <p class="text-muted mb-sm-0">
                                        Showing 1 to 10 of 57 entries
                                      </p>
                                      <nav class="ms-auto">
                                        <ul class="pagination separated pagination-info mb-sm-0">
                                          <li class="page-item">
                                            <a class="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                              <i class="icon-arrow-left"></i>
                                            </a>
                                          </li>
                                          {renderPagination()}

                                          <li class="page-item">
                                            <a class="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                              <i class="icon-arrow-right"></i>
                                            </a>
                                          </li>
                                        </ul>
                                      </nav>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* your content end */}
                      </div>
                    </div>
                  </div>
                </div>

                <footer class="footer">
                  <div class="d-sm-flex justify-content-center justify-content-sm-between">
                    <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">
                      Copyright © 2024 Thartech It Solution. All rights reserved.{' '}
                      <a href="#"> Terms of use</a>
                      <a href="#">Privacy Policy</a>
                    </span>
                    <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                      {' '}
                      Made By <i class="icon-heart text-danger"></i> Thartech It Solution
                    </span>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
};

export default FastParityBat;
