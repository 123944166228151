import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {PageTitle} from '../PageTitle';
import {UserContext} from '../globalContext';
import {useNavigate} from 'react-router-dom';

import axiosInstance from '../axiosInstance';
import logo from '../.././src/images/hemadesiludo.png';
import face from '../.././src/images/face8.jpg';
import wollet from '../images/wallet.32eb69bea0c4c697ebf9.png';
import Report from './report';
import io from 'socket.io-client';

const socket = io('https://server.newfastwin.com');

const HomeScreen = props => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleToggleClick = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const user = useContext(UserContext);
  let navigate = useNavigate();

  const [parityBtnClass, setParityBtnClass] = useState('btn btn-success p-2 ');
  const [diceBtnClass, setDiceBtnClass] = useState('btn btn-success p-2 ');
  const [avtBtnClass, setAvtBtnClass] = useState('btn btn-success p-2 disabled ');
  const [todayUser, setTodayUser] = useState();
  const [todayRecharge, setTodayrecharge] = useState();
  const [todayWithdraw, setTodayWithdraw] = useState();
  const [todayProfit, setTodayProfit] = useState();
  const [todayCGame, setTodayCGame] = useState();
  const [todaySGame, setTodaySGame] = useState();
  const [todayCGame2, setTodayCGame2] = useState();
  const [todaySGame2, setTodaySGame2] = useState();

  const [allUser, setAllUser] = useState();
  const [allRecharge, setAllRecharge] = useState();
  const [allWithdraw, setAllWithdraw] = useState();
  const [allProfit, setAllProfit] = useState();
  const [allSGame, setAllSGame] = useState();
  const [allCGame, setAllCGame] = useState();
  const [allSGame2, setAllSGame2] = useState();
  const [allCGame2, setAllCGame2] = useState();
  const [allRechargeReq, setAllRechargeReq] = useState();
  const [allWithdrawReq, setAllWithdrawReq] = useState();
  const [allUnsolvedGame, setAllUnsolvedGame] = useState();
  const [allUnsolvedGame2, setAllUnsolvedGame2] = useState();



  const [message, setMessage] = useState('');
  const [chat, setChat] = useState([]);
  var ChackRuning = 1
  useEffect(() => {


          // Receive messages from server
          socket.on('message', (msg) => {

                  // console.log("message",msg)
                  if (msg == "batStart") {
                    getPeriod44();
                      setAvtBtnClass('btn btn-success p-2 ')
                  }
                  if (msg.countDown == 2) {
                    setAvtBtnClass('btn btn-success p-2 disabled')
                  }
                  if (msg == "start") {
                       
                  }
                  if (msg.plan == "start") {
                         

                  }
                  if (msg.plan == "stop") {
                         

                  }
                  // setMulty(msg)
                  //     setChat((prevChat) => [...prevChat, msg]);
          });

          // Cleanup when component unmounts
          return () => {

                  socket.off('message');
          };
  }, []);











  // useEffect(() => {

  //     let mounted = true;
  //     if (mounted) {

  //         if (user.userId == null) {
  //             window.location.reload(true);
  //         }

  //     }
  //     pageLoad();
  //     return () => (mounted = false);
  // }, []);

  const pageLoad = () => {
    getTodayUser();
    getAllUser();
    getAllRecharge();
    getAllWithdraw();
    getAllProfit();
    getAllCGame();
    getAllSGame();
    getAllCGame2();
    getAllSGame2();
    getTodayRecharge();
    getTodayWithdraw();
    getTodayProfit();
    getTodayCGame();
    getTodaySGame();
    getTodayCGame2();
    getTodaySGame2();
    getAllRechargeReq();
    getAllWithdrawReq();
    getAllUnsolvedGame1();
    getAllUnsolvedGame2();
   
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = `/`;
  };
  const getTodayUser = () => {
    const data = {
      fromDate: new Date(),
      toDate: new Date(),
    };
    console.log('datadatadata', data);
    axiosInstance.post(`/member/report`, data).then(response => {
      console.log('todayuserlength', response.data.data);
      if (response.data.data.length > 0) {
        setTodayUser(...response.data.data);
      } else {
        setTodayUser({count: 0});
      }
    });
  };
  const getAllUser = () => {
    axiosInstance.post(`/member/report`).then(response => {
      if (response.data.data.length > 0) {
        setAllUser(...response.data.data);
      } else {
        setAllUser({count: 0});
      }
    });
  };
  const getTodayRecharge = () => {
    const data = {
      fromDate: new Date(),
      toDate: new Date(),
    };
    axiosInstance.post(`/payment/report`, data).then(response => {
      if (response.data.data.length > 0) {
        setTodayrecharge(...response.data.data);
      } else {
        setTodayrecharge({amount: 0});
      }
    });
  };
  const getAllRecharge = () => {
    axiosInstance.post(`/payment/report`).then(response => {
      if (response.data.data.length > 0) {
        setAllRecharge(...response.data.data);
      } else {
        setAllRecharge({amount: 0});
      }
    });
  };
  const getTodayWithdraw = () => {
    const data = {
      fromDate: new Date(),
      toDate: new Date(),
    };
    axiosInstance.post(`/withdraw/report`, data).then(response => {
      if (response.data.data.length > 0) {
        setTodayWithdraw(...response.data.data);
      } else {
        setTodayWithdraw({amount: 0});
      }
    });
  };
  const getAllWithdraw = () => {
    axiosInstance.post(`/withdraw/report`).then(response => {
      if (response.data.data.length > 0) {
        setAllWithdraw(...response.data.data);
      } else {
        setAllWithdraw({amount: 0});
      }
    });
  };
  const getTodayProfit = () => {
    const data = {
      fromDate: new Date(),
      toDate: new Date(),
    };
    axiosInstance.post(`/profit/report`, data).then(response => {
      if (response.data.data.length > 0) {
        setTodayProfit(...response.data.data);
      } else {
        setTodayProfit({amount: 0});
      }
    });
  };
  const getAllProfit = () => {
    axiosInstance.post(`/profit/report`).then(response => {
      if (response.data.data.length > 0) {
        setAllProfit(...response.data.data);
      } else {
        setAllProfit({amount: 0});
      }
    });
  };
  const getTodaySGame = () => {
    const data = {
      fromDate: new Date(),
      toDate: new Date(),
    };
    axiosInstance.post(`/game/report`, data).then(response => {
      if (response.data.data.length > 0) {
        setTodaySGame(...response.data.data);
      } else {
        setTodaySGame({count: 0});
      }
    });
  };
  const getAllSGame = () => {
    axiosInstance.post(`/game/report`).then(response => {
      if (response.data.data.length > 0) {
        setAllSGame(...response.data.data);
      } else {
        setAllSGame({count: 0});
      }
    });
  };
  const getTodayCGame = () => {
    const data = {
      fromDate: new Date(),
      toDate: new Date(),
    };
    axiosInstance.post(`/game/report2`, data).then(response => {
      if (response.data.data.length > 0) {
        setTodayCGame(...response.data.data);
      } else {
        setTodayCGame({count: 0});
      }
    });
  };
  const getAllCGame = () => {
    axiosInstance.post(`/game/report2`).then(response => {
      if (response.data.data.length > 0) {
        setAllCGame(...response.data.data);
      } else {
        setAllCGame({count: 0});
      }
    });
  };

  const getTodaySGame2 = () => {
    const data = {
      fromDate: new Date(),
      toDate: new Date(),
    };
    axiosInstance.post(`/game2/report`, data).then(response => {
      if (response.data.data.length > 0) {
        setTodaySGame2(...response.data.data);
      } else {
        setTodaySGame2({count: 0});
      }
    });
  };
  const getAllSGame2 = () => {
    axiosInstance.post(`/game2/report`).then(response => {
      if (response.data.data.length > 0) {
        setAllSGame2(...response.data.data);
      } else {
        setAllSGame2({count: 0});
      }
    });
  };
  const getTodayCGame2 = () => {
    const data = {
      fromDate: new Date(),
      toDate: new Date(),
    };
    axiosInstance.post(`/game2/report2`, data).then(response => {
      if (response.data.data.length > 0) {
        setTodayCGame2(...response.data.data);
      } else {
        setTodayCGame2({count: 0});
      }
    });
  };
  const getAllCGame2 = () => {
    axiosInstance.post(`/game2/report2`).then(response => {
      if (response.data.data.length > 0) {
        setAllCGame2(...response.data.data);
      } else {
        setAllCGame2({count: 0});
      }
    });
  };
  const getAllRechargeReq = () => {
    axiosInstance.post(`/payment/report2`).then(response => {
      if (response.data.data.length > 0) {
        setAllRechargeReq(...response.data.data);
      } else {
        setAllRechargeReq({count: 0});
      }
    });
  };
  const getAllWithdrawReq = () => {
    axiosInstance.post(`/withdraw/report2`).then(response => {
      if (response.data.data.length > 0) {
        setAllWithdrawReq(...response.data.data);
      } else {
        setAllWithdrawReq({count: 0});
      }
    });
  };
  const getAllUnsolvedGame1 = () => {
    axiosInstance.post(`/game1f/report`).then(response => {
      if (response.data.data.length > 0) {
        setAllUnsolvedGame(...response.data.data);
      } else {
        setAllUnsolvedGame({count: 0});
      }
    });
  };
  const getAllUnsolvedGame2 = () => {
    axiosInstance.post(`/game2f/report`).then(response => {
      if (response.data.data.length > 0) {
        setAllUnsolvedGame2(...response.data.data);
      } else {
        setAllUnsolvedGame2({count: 0});
      }
    });
  };
  const [sec11, setSec11] = useState(null);
  const [sec22, setSec22] = useState(null);
  const [min11, setMin11] = useState(null);
  const [Period11, setPeriod11] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
        pageLoad3();
        //RiderLocation();
    }, 1000);
    return () => clearInterval(interval);

}, []);
const pageLoad3 = () => {
    Countdown();
}
const Countdown = () => {
  var countDownDate = Date.parse(new Date) / 1e3;
  //var now = new Date().getTime();
  var distance = 30 - countDownDate % 30;

  var i = distance / 60,
      n = distance % 60;
  //o = n / 10,
  // s = n % 10;
  var minutes = Math.floor(i);
  setMin11(minutes);
  var seconds = ('0' + Math.floor(n)).slice(-2);
  var sec1 = (seconds % 100 - seconds % 10) / 10;
  setSec11(sec1);
  var sec2 = seconds % 10;
  setSec22(sec2);


  if (seconds === "29") {
      getPeriod();
      getPeriod33();
  }
  if (seconds > 10) {
    setDiceBtnClass('btn btn-success p-2')
      setIsOpen(isOpen);
  }
  else {
      if (seconds < 10) {
    setDiceBtnClass('btn btn-success p-2 disabled ')

          setIsOpen(!isOpen);
      }
  }

}
const [isOpen, setIsOpen] = useState(false);
const getPeriod = () => {

  axiosInstance.post('/period/fastparity').then((res) => {
      setPeriod11(res.data.data[0].period)
  })
}

const [sec1, setSec1] = useState(null);
const [sec2, setSec2] = useState(null);
const [min, setMin] = useState(null);
const [Period, setPeriod] = useState(0);
const [PeriodDice, setPeriodDice] = useState(0);
const [PeriodAviator, setPeriodAviator] = useState(0);
useEffect(() => {
  const interval = setInterval(() => {
      pageLoad22();
  }, 1000);
  return () => clearInterval(interval);

}, []);
const pageLoad22 = () => {
  Countdown22();
}
const Countdown22 = () => {
  var countDownDate = Date.parse(new Date) / 1e3;
  //var now = new Date().getTime();
  var distance = 180 - countDownDate % 180;

  var i = distance / 60,
      n = distance % 60;
  //o = n / 10,
  // s = n % 10;
  var minutes = Math.floor(i);
  setMin(minutes);
  var seconds = ('0' + Math.floor(n)).slice(-2);
  var sec1 = (seconds % 100 - seconds % 10) / 10;
  setSec1(sec1);
  var sec2 = seconds % 10;
  setSec2(sec2);


  if (distance === "179") {
      getPeriod22();

  }
  if (distance > 30) {
    setParityBtnClass('btn btn-success p-2')
      setIsOpen(isOpen);
  }
  else {
      if (distance < 30) {
    setParityBtnClass('btn btn-success p-2 disabled ')

          setIsOpen(!isOpen);
      }
  }

}
const getPeriod22 = () => {

  axiosInstance.post('/period/parity').then((res) => {
      setPeriod(res.data.data[0].period)
  })
}

const getPeriod33 = () => {

  axiosInstance.post('/period/dice').then((res) => {
      setPeriodDice(res.data.data[0].period)
  })
}

const getPeriod44 = () => {

  axiosInstance.post('/period/avitor').then((res) => {
      setPeriodAviator(res.data.data[0].period)
  })
}
  useEffect(() => {

      let mounted = true;
      if (mounted) {

       
      }
      getPeriod22();
      getPeriod33();
      getPeriod44();
      getPeriod();
      return () => (mounted = false);
  }, []);

  const [fastResult, setFastResult] = useState(null);
  const [parityResult, setParityResult] = useState(null);
  const [diceResult, setDiceResult] = useState(null);
  const [aviatorResult, setAviatorResult] = useState(null);
const onfastResultChange = (e)=>{
console.log("eeeee",e)

}
const onAddFastParityResult = () =>{
 if(fastResult !== null){
  const data = {
    gameDate: new Date(),
    period: Period11 ,
    result:fastResult,
  }
  axiosInstance.post('/manualresult/add2',data).then((res)=>{

    if(res.data.responseCode === 1 ){
      alert(`your selected result add ${res.data.data.result} successfully `)
    }
  })
 }
 else{
  alert("please select Result")
 }
}
const onAddParityResult = () =>{
  if(parityResult !== null){
   const data = {
     gameDate: new Date(),
     period: Period ,
     result:parityResult,
   }
   axiosInstance.post('/manualresult/add1',data).then((res)=>{
 
     if(res.data.responseCode === 1 ){
       alert(`your selected result add ${res.data.data.result} successfully `)
     }
   })
  }
  else{
   alert("please select Result")
  }
 }

 const onAddDiceResult = () =>{
  if(diceResult !== null){
   const data = {
     gameDate: new Date(),
     period: PeriodDice ,
     result:diceResult,
   }
   console.log("data",data)
   axiosInstance.post('/dice/addResult',data).then((res)=>{
 
     if(res.data.responseCode === 1 ){
       alert(`your selected result add ${res.data.data.result} successfully `)
     }
   })
  }
  else{
   alert("please select Result")
  }
 }


 const onAddAviatorResult = () =>{
  if(aviatorResult !== null && aviatorResult < 18){
   const data = {
     gameDate: new Date(),
     period: PeriodAviator ,
     result:aviatorResult,
   }
   console.log("data",data)
   axiosInstance.post('/avitor/addResultM',data).then((res)=>{
 
     if(res.data.responseCode === 1 ){
       alert(`your selected result add ${res.data.data.result} successfully `)
     }
   })
  }
  else{
   alert("please select Result between 1-17")
  }
 }






  return (
    <div className={` ${isSidebarVisible ? 'sidebar-icon-only' : ''}`}>
      {/* //nav top header start */}
      <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a class="navbar-brand brand-logo" href="javascript:void(0)">
            <img src={logo} alt="logo" class="logo-dark" />
          </a>
          <a class="navbar-brand brand-logo-mini" href="javascript:void(0)"></a>
          <button
            class="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
            onClick={handleToggleClick}>
            <span class="icon-menu"></span>
          </button>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-center">
          <h5 class="mb-0 font-weight-medium d-none d-lg-flex">
            Welcome Your dashboard!
          </h5>
          <ul class="navbar-nav navbar-nav-right">
       
              <li>
              <div><a class="text-decoration-none text-white " href="javascriptvoid:(0)">
            <div class="py-1 bg-white border px-2 text-dark d-flex align-items-center rounded-2">
          <img className='wllet' src={wollet} alt="" srcset="" />
          {allProfit === undefined && <strong>0</strong>}
              {allProfit !== undefined && <strong>{allProfit.amount}</strong>}</div>
          </a></div>
              </li>
    

            <li class="nav-item dropdown d-none d-xl-inline-flex user-dropdown">
              <a
                class="nav-link dropdown-toggle"
                id="UserDropdown"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <img
                  class="img-xs rounded-circle ms-2"
                  src={face}
                  alt="Profile image"
                />
                <span class="font-weight-normal"> Admin </span>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right navbar-dropdown"
                aria-labelledby="UserDropdown">
                <div class="dropdown-header text-center">
                  <img
                    class="img-md rounded-circle"
                    src={face}
                    alt="Profile image"
                  />
                  <p class="mb-1 mt-3">Admin</p>
                  <p class="font-weight-light text-muted mb-0">
                    Admin@gmail.com
                  </p>
                </div>
              
              
                <a class="dropdown-item"  onClick={()=>handleLogout()}>
                  <i class="dropdown-item-icon icon-power text-primary" ></i>Log
                  Out
                </a>
              </div>
            </li>
          </ul>
          <button
            class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
            onClick={handleToggleClick}>
            <span class="icon-menu"></span>
          </button>
        </div>
      </nav>
      {/* //nav top header end */}

      <div class="container-scroller">
        <div class="container-fluid page-body-wrapper">
      {/* //sidebar start */}

          <nav
            class={`sidebar sidebar-offcanvas ${
              isSidebarVisible ? 'sidebar sidebar-offcanvas active' : ''
            }`}
            id="sidebar">
            <div
              class=""
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical">
              <ul class="nav">
                {/* <li class="nav-item navbar-brand-mini-wrapper">
                  <a
                    class="nav-link navbar-brand brand-logo-mini"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true">
                    <i class="icon-screen-desktop menu-icon"></i>
                  </a>
                </li> */}
                <li class="nav-item nav-profile">
                  <a href="javascript:void(0)" class="nav-link ">
                    <div class="profile-image">
                      <img
                        class="img-xs rounded-circle"
                        src={face}
                        alt="profile image"
                      />
                      <div class="dot-indicator bg-success"></div>
                    </div>
                    <div class="text-wrapper">
                      <p class="profile-name">Admin</p>
                      <p class="designation">Administrator</p>
                    </div>
                    <div class="icon-container mt-3">
                      <i class="icon-bubbles"></i>
                      <div class="dot-indicator bg-danger"></div>
                    </div>
                  </a>
                </li>

                <li class="nav-item active">
                  <a
                    class="nav-link active"
                  
                     href="#/HomeScreen">
                    <span class="menu-title">Dashboard</span>
                    <i class="icon-screen-desktop menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/ARecharge"
                   >
                    <span class="menu-title">Recharge Request</span>
                    <i class="icon-energy menu-icon"></i>

                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/AllRecharge"
                   >
                    <span class="menu-title">All Recharge</span>
                    <i class="icon-energy menu-icon"></i>

                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/AllWithdraw"
                   >
                    <span class="menu-title">All Withdraw</span>
                    <i class="icon-wallet menu-icon"></i>

                    
                  </a>
                </li>  <li class="nav-item">
                  <a class="nav-link"
                  href="#/AWithdraw"
                   >
                    <span class="menu-title">Withdraw Request</span>
                    <i class="icon-wallet menu-icon"></i>

                    
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                 
                   
                    href="#/member"
                    >
                    <span class="menu-title">Active User</span>
                    <i class="icon-user menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#/deletedMember">
                    <span class="menu-title" >Deleted User</span>
                    <i class="icon-user-unfollow menu-icon"></i>

                  </a>
                </li>
             
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/fastParityBat"

                   >
                    <span class="menu-title">Fast Parity Bat</span>
                    <i class="icon-book-open menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/parityBat"
                   >
                    <span class="menu-title">Parity Bat</span>
                    <i class="icon-wallet menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/fastParityResult">
                   
                    <span class="menu-title">Fast Parity Result</span>
                    <i class="icon-trophy menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/parityResult"
                   >
                    <span class="menu-title">Parity Result</span>
                    <i class="icon-grid menu-icon"></i>
                  </a>
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/diceBat"

                   >
                    <span class="menu-title">Dice Bat</span>
                    <i class="icon-book-open menu-icon"></i>
                  </a>
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/diceResult"

                   >
                    <span class="menu-title">Dice Result</span>
                    <i class="icon-book-open menu-icon"></i>
                  </a>
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/aviatorBat"

                   >
                    <span class="menu-title">Aviator Bat</span>
                    <i class="icon-book-open menu-icon"></i>
                  </a>
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/aviatorResult"

                   >
                    <span class="menu-title">Aviator Result</span>
                    <i class="icon-book-open menu-icon"></i>
                  </a>
                </li>




                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/profittable"
                   >
                    <span class="menu-title">Profit Table</span>
                    <i class="icon-shield menu-icon"></i>

                  </a>
                </li>
           
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/upi" >
                    <span class="menu-title">UPI Change</span>
                    <i class="icon-disc menu-icon"></i>

                  </a>
                </li>
             
          
              
     
              </ul>
            </div>
          </nav>
      {/* //sidebar end */}

          <div class="main-panel">
            <div class="content-wrapper">
              <div>
                <div class="tab-content" id="v-pills-tabContent">
                <div
                    class="tab-pane fade show active"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab">
                      {/* your content */}

              

                    <div class="row quick-action-toolbar">
                      <div class="col-md-12 grid-margin">
                        <div class="card ">
                          <div class="card-header d-block d-md-flex">
                            <h5 class="mb-0">Send Messages & prediction</h5>
                          </div>
                          <div className="m-3">
                            <div class="input-container">
                              <input
                                required=""
                                placeholder="Type Message...."
                                type="email"
                              />
                              <button class="invite-btn" type="button">
                                Send 
                              </button>
                            </div>
                          </div>
                          <div class="row d-flex">
                     <div className='col-md-6'>
                     <div class="col-md-12 grid-margin stretch-card">
                        <div class="card">
                          <div class="card-body">
                            <div class="d-sm-flex align-items-center mb-4">
                              <h4 class="card-title mb-sm-0">
                              Fast Parity 
                              prediction
                              </h4>
                              <a
                                href="#"
                                class="text-dark ms-auto mb-3 mb-sm-0">
                          { sec11 !== null && sec22 !== null && min11 !== null &&  <h2 className='mb-0 text-danger'>TIME: 00 : {sec11}{sec22} </h2>}
                              </a>
                            </div>
                            <div class="" >
                            <select class="form-select text-dark" placeholder="Enter a number from 0-9" onChange={(e)=>setFastResult(e.target.value)}>
  <option value="0" disabled selected>Enter a number from 0-9</option>

  <option value="1">1</option>
  <option value="2">2</option>
  <option value="3">3</option>
  <option value="4">4</option>
  <option value="5">5</option>
  <option value="6">6</option>
  <option value="7">7</option>
  <option value="8">8</option>
  <option value="9">9</option>
  <option value="0">0</option>
</select>
                            </div>
                            <div class="d-flex mt-4 justify-content-between">
                             <h2 className='mb-0 text-info'>Period No. : {Period11} </h2>
                             <div></div>
                            <button class={diceBtnClass} type="button" onClick={()=>onAddFastParityResult()}>Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                     </div>
                     <div className='col-md-6'>
                     <div class="col-md-12 grid-margin stretch-card">
                     <div class="card">
                          <div class="card-body">
                            <div class="d-sm-flex align-items-center mb-4 mt-2">
                              <h4 class="card-title mb-sm-0">
                              Parity 
                              prediction
                              </h4>
                              <a
                                href="#"
                                class="text-dark ms-auto mb-3 mb-sm-0">
                             <h2 className='mb-0 text-danger'>TIME: 0{min} :{sec1}{sec2} </h2>
                              </a>
                            </div>
                            <div class="" >
                            <select class="form-select text-dark" placeholder="Enter a number from 0-9" onChange={(e)=>setParityResult(e.target.value)}>
  <option value="0" disabled selected>Enter a number from 0-9</option>

  <option value="1">1</option>
  <option value="2">2</option>
  <option value="3">3</option>
  <option value="4">4</option>
  <option value="5">5</option>
  <option value="6">6</option>
  <option value="7">7</option>
  <option value="8">8</option>
  <option value="9">9</option>
  <option value="0">0</option>
</select>
                            </div>
                            <div class="d-flex mt-4 justify-content-between">
                             <h2 className='mb-0 text-info'>Period No. : {Period} </h2>
                             <div></div>
                            <button class={parityBtnClass} type="button" onClick={()=>onAddParityResult()}>Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                     </div>
                     <div className='col-md-6'>
                     <div class="col-md-12 grid-margin stretch-card">
                        <div class="card">
                          <div class="card-body">
                            <div class="d-sm-flex align-items-center mb-4">
                              <h4 class="card-title mb-sm-0">
                              Dice
                              prediction
                              </h4>
                              <a
                                href="#"
                                class="text-dark ms-auto mb-3 mb-sm-0">
                          { sec11 !== null && sec22 !== null && min11 !== null &&  <h2 className='mb-0 text-danger'>TIME: 00 : {sec11}{sec22} </h2>}
                              </a>
                            </div>
                            <div class="" >
                            <input class="form-type text-dark" placeholder="Enter a number from 1-98" onChange={(e)=>setDiceResult(e.target.value)}/>

                            </div>
                            <div class="d-flex mt-4 justify-content-between">
                             <h2 className='mb-0 text-info'>Period No. : {PeriodDice} </h2>
                             <div></div>
                            <button class={diceBtnClass} type="button" onClick={()=>onAddDiceResult()}>Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                     </div>
                     <div className='col-md-6'>
                     <div class="col-md-12 grid-margin stretch-card">
                     <div class="card">
                          <div class="card-body">
                            <div class="d-sm-flex align-items-center mb-4 mt-2">
                              <h4 class="card-title mb-sm-0">
                              Aviator 
                              prediction
                              </h4>
                              <a
                                href="#"
                                class="text-dark ms-auto mb-3 mb-sm-0">
                             <h2 className='mb-0 text-danger'>TIME: 00 :{sec1}{sec2} </h2>
                              </a>
                            </div>
                            <div class="" >
                            <input class="form-type text-dark" placeholder="Enter a multiplier from 0-50" onChange={(e)=>setAviatorResult(e.target.value)}/>

                            </div>
                            <div class="d-flex mt-4 justify-content-between">
                             <h2 className='mb-0 text-info'>Period No. : {PeriodAviator} </h2>
                             <div></div>
                            <button class={avtBtnClass} type="button" onClick={()=>onAddAviatorResult()}>Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                     </div>
                    </div>
                        </div>
                      </div>
                    </div>
                  <Report/>
                  {/* <div class="row d-flex">
                     <div className='col-md-6'>
                     <div class="col-md-12 grid-margin stretch-card">
                        <div class="card">
                          <div class="card-body">
                            <div class="d-sm-flex align-items-center mb-4">
                              <h4 class="card-title mb-sm-0">
                              Fast Parity Result
                              </h4>
                              <a
                                href="#"
                                class="text-dark ms-auto mb-3 mb-sm-0">
                                {' '}
                                View all
                              </a>
                            </div>
                            <div class="table-responsive border rounded p-1">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th class="font-weight-bold">Period</th>
                                    <th class="font-weight-bold">Result</th>
                                    <th class="font-weight-bold">Profit</th>
                                
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>3621</td>
                                    <td>alipay</td>
                                    <td>04</td>
                                   
                                  </tr>
                                  <tr>
                                    <td>6245</td>
                                    <td>voilate</td>
                                    <td>25</td>
                                  
                                  </tr>
                                  <tr>
                                    <td>9265</td>
                                    <td>red</td>
                                    <td>12</td>
                                
                                  </tr>
                                  <tr>
                                    <td>2263</td>
                                    <td>green</td>
                                    <td>30</td>
                                  
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          
                          </div>
                        </div>
                      </div>
                     </div>
                     <div className='col-md-6'>
                     <div class="col-md-12 grid-margin stretch-card">
                        <div class="card">
                          <div class="card-body">
                            <div class="d-sm-flex align-items-center mb-4">
                              <h4 class="card-title mb-sm-0">
                              Parity Result
                              </h4>
                              <a
                                href="#"
                                class="text-dark ms-auto mb-3 mb-sm-0">
                                {' '}
                                View all
                              </a>
                            </div>
                            <div class="table-responsive border rounded p-1">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th class="font-weight-bold">Period</th>
                                    <th class="font-weight-bold">Result</th>
                                    <th class="font-weight-bold">Profit</th>
                                
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>3621</td>
                                    <td>alipay</td>
                                    <td>04</td>
                                   
                                  </tr>
                                  <tr>
                                    <td>6245</td>
                                    <td>voilate</td>
                                    <td>25</td>
                                  
                                  </tr>
                                  <tr>
                                    <td>9265</td>
                                    <td>red</td>
                                    <td>12</td>
                                
                                  </tr>
                                  <tr>
                                    <td>2263</td>
                                    <td>green</td>
                                    <td>30</td>
                                  
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                           
                          </div>
                        </div>
                      </div>
                     </div>
                    </div>
                 
                    <div
                    class="tab-pane fade"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab">
                    {' '}
                
                  </div> */}

                

                      {/* your content end */}

                      </div>
                </div>
              </div>
            </div>

            <footer class="footer">
              <div class="d-sm-flex justify-content-center justify-content-sm-between">
                <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">
                  Copyright © 2024 Thartech It Solution solutions , All rights reserved.{' '}
                  <a href="#"> Terms of use</a>
                  <a href="#">Privacy Policy</a>
                </span>
                <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                  {' '}
                  Made By <i class="icon-heart text-danger"></i> Thartech It Solution
                </span>
              </div>
            </footer>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default HomeScreen;
