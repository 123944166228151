import React, {useContext, useEffect, useState} from 'react';
import axiosInstance from '../axiosInstance';
import {UserContext} from '../globalContext';


import {Button} from 'antd';
import { useNavigate } from "react-router-dom";

const Report= () => {

    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleToggleClick = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const user = useContext(UserContext);
  let navigate = useNavigate();

  const [todayUser, setTodayUser] = useState();
  const [todayRecharge, setTodayrecharge] = useState();
  const [todayWithdraw, setTodayWithdraw] = useState();
  const [todayProfit, setTodayProfit] = useState();
  const [todayCGame, setTodayCGame] = useState();
  const [todaySGame, setTodaySGame] = useState();
  const [todayCGame2, setTodayCGame2] = useState();
  const [todaySGame2, setTodaySGame2] = useState();

  const [allUser, setAllUser] = useState();
  const [allRecharge, setAllRecharge] = useState();
  const [allWithdraw, setAllWithdraw] = useState();
  const [allProfit, setAllProfit] = useState();
  const [allSGame, setAllSGame] = useState();
  const [allCGame, setAllCGame] = useState();
  const [allSGame2, setAllSGame2] = useState();
  const [allCGame2, setAllCGame2] = useState();
  const [allRechargeReq, setAllRechargeReq] = useState();
  const [allWithdrawReq, setAllWithdrawReq] = useState();
  const [allUnsolvedGame, setAllUnsolvedGame] = useState();
  const [allUnsolvedGame2, setAllUnsolvedGame2] = useState();

useEffect(() => {

      let mounted = true;
      if (mounted) {

          if (user.userId == null) {
              window.location.reload(true);
          }

      }
      pageLoad();
      return () => (mounted = false);
  }, []);
    const pageLoad = () => {
        getTodayUser();
        getAllUser();
        getAllRecharge();
        getAllWithdraw();
        getAllProfit();
     
        getTodayRecharge();
        getTodayWithdraw();
        getTodayProfit();
     
        getAllRechargeReq();
        getAllWithdrawReq();
 
      };
      const today = new Date();
      const getTodayUser = () => {
        const data = {
          fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
          toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
        };
        console.log('datadatadata', data);
        axiosInstance.post(`/member/report`, data).then(response => {
          console.log('todayuserlength', response.data.data);
          if (response.data.data.length > 0) {
            setTodayUser(...response.data.data);
          } else {
            setTodayUser({count: 0});
          }
        });
      };
      const getAllUser = () => {
        axiosInstance.post(`/member/report`).then(response => {
          if (response.data.data.length > 0) {
            setAllUser(...response.data.data);
          } else {
            setAllUser({count: 0});
          }
        });
      };
      const getTodayRecharge = () => {
        const data = {
          fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
          toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
        };
        axiosInstance.post(`/payment/report`, data).then(response => {
          if (response.data.data.length > 0) {
            setTodayrecharge(...response.data.data);
          } else {
            setTodayrecharge({amount: 0});
          }
        });
      };
      const getAllRecharge = () => {
        axiosInstance.post(`/payment/report`).then(response => {
          if (response.data.data.length > 0) {
            setAllRecharge(...response.data.data);
          } else {
            setAllRecharge({amount: 0});
          }
        });
      };
      const getTodayWithdraw = () => {
        const data = {
          fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
          toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
        };
        axiosInstance.post(`/withdraw/report`, data).then(response => {
          if (response.data.data.length > 0) {
            setTodayWithdraw(...response.data.data);
          } else {
            setTodayWithdraw({amount: 0});
          }
        });
      };
      const getAllWithdraw = () => {
        axiosInstance.post(`/withdraw/report`).then(response => {
          if (response.data.data.length > 0) {
            setAllWithdraw(...response.data.data);
          } else {
            setAllWithdraw({amount: 0});
          }
        });
      };
      const getTodayProfit = () => {
        const data = {
          fromDate: new Date(),
          toDate: new Date(),
        };
        axiosInstance.post(`/profit/report`, data).then(response => {
          if (response.data.data.length > 0) {
            setTodayProfit(...response.data.data);
          } else {
            setTodayProfit({amount: 0});
          }
        });
      };
      const getAllProfit = () => {
        axiosInstance.post(`/profit/report`).then(response => {
          if (response.data.data.length > 0) {
            setAllProfit(...response.data.data);
          } else {
            setAllProfit({amount: 0});
          }
        });
      };
      const getTodaySGame = () => {
        const data = {
          fromDate: new Date(),
          toDate: new Date(),
        };
        axiosInstance.post(`/game/report`, data).then(response => {
          if (response.data.data.length > 0) {
            setTodaySGame(...response.data.data);
          } else {
            setTodaySGame({count: 0});
          }
        });
      };
      const getAllSGame = () => {
        axiosInstance.post(`/game/report`).then(response => {
          if (response.data.data.length > 0) {
            setAllSGame(...response.data.data);
          } else {
            setAllSGame({count: 0});
          }
        });
      };
      const getTodayCGame = () => {
        const data = {
          fromDate: new Date(),
          toDate: new Date(),
        };
        axiosInstance.post(`/game/report2`, data).then(response => {
          if (response.data.data.length > 0) {
            setTodayCGame(...response.data.data);
          } else {
            setTodayCGame({count: 0});
          }
        });
      };
      const getAllCGame = () => {
        axiosInstance.post(`/game/report2`).then(response => {
          if (response.data.data.length > 0) {
            setAllCGame(...response.data.data);
          } else {
            setAllCGame({count: 0});
          }
        });
      };
    
      const getTodaySGame2 = () => {
        const data = {
          fromDate: new Date(),
          toDate: new Date(),
        };
        axiosInstance.post(`/game2/report`, data).then(response => {
          if (response.data.data.length > 0) {
            setTodaySGame2(...response.data.data);
          } else {
            setTodaySGame2({count: 0});
          }
        });
      };
      const getAllSGame2 = () => {
        axiosInstance.post(`/game2/report`).then(response => {
          if (response.data.data.length > 0) {
            setAllSGame2(...response.data.data);
          } else {
            setAllSGame2({count: 0});
          }
        });
      };
      const getTodayCGame2 = () => {
        const data = {
          fromDate: new Date(),
          toDate: new Date(),
        };
        axiosInstance.post(`/game2/report2`, data).then(response => {
          if (response.data.data.length > 0) {
            setTodayCGame2(...response.data.data);
          } else {
            setTodayCGame2({count: 0});
          }
        });
      };
      const getAllCGame2 = () => {
        axiosInstance.post(`/game2/report2`).then(response => {
          if (response.data.data.length > 0) {
            setAllCGame2(...response.data.data);
          } else {
            setAllCGame2({count: 0});
          }
        });
      };
      const getAllRechargeReq = () => {
        axiosInstance.post(`/payment/report2`).then(response => {
          if (response.data.data.length > 0) {
            setAllRechargeReq(...response.data.data);
          } else {
            setAllRechargeReq({count: 0});
          }
        });
      };
      const getAllWithdrawReq = () => {
        axiosInstance.post(`/withdraw/report2`).then(response => {
          if (response.data.data.length > 0) {
            setAllWithdrawReq(...response.data.data);
          } else {
            setAllWithdrawReq({count: 0});
          }
        });
      };
      const getAllUnsolvedGame1 = () => {
        axiosInstance.post(`/game1f/report`).then(response => {
          if (response.data.data.length > 0) {
            setAllUnsolvedGame(...response.data.data);
          } else {
            setAllUnsolvedGame({count: 0});
          }
        });
      };
      const getAllUnsolvedGame2 = () => {
        axiosInstance.post(`/game2f/report`).then(response => {
          if (response.data.data.length > 0) {
            setAllUnsolvedGame2(...response.data.data);
          } else {
            setAllUnsolvedGame2({count: 0});
          }
        });
      };
    
  return (
    <div >
   <div class="row">
                      <div class="col-md-12 grid-margin">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="d-sm-flex align-items-baseline report-summary-header">
                                  <h5 class="font-weight-semibold">
                                    Report Summary
                                  </h5>{' '}
                                  <span class="ms-auto">Updated Report</span>{' '}
                                  <button class="btn btn-icons border-0 p-2">
                                    <i class="icon-refresh"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="row report-inner-cards-wrapper">
                              <div class=" col-md -6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                    Today & Total Users
                                  </span>
                                  {todayUser === undefined &&
                                    allUser === undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        0/0
                                      </div>
                                    )}
                                  {todayUser !== undefined &&
                                    allUser !== undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        {todayUser.count}/{allUser.count}
                                      </div>
                                    )}

                                  <a class="report-count" href="#/member">
                                    {' '}
                                    view all
                                  </a>
                                </div>
                                <div class="inner-card-icon bg-success">
                                  <i class="icon-user"></i>
                                </div>
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                    Today & Total Recharge
                                  </span>
                                  {todayRecharge === undefined &&
                                    allRecharge === undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        0/0
                                      </div>
                                    )}
                                  {todayRecharge !== undefined &&
                                    allRecharge !== undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        {todayRecharge.amount}/
                                        {allRecharge.amount}
                                      </div>
                                    )}

                                  <a class="report-count" href="#/ARecharge">
                                    {' '}
                                    view all
                                  </a>
                                </div>
                                <div class="inner-card-icon bg-danger">
                                  <i class="icon-energy menu-icon"></i>
                                </div>
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                    Today & Total Withdr..
                                  </span>
                                  {todayWithdraw === undefined &&
                                    allWithdraw === undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        0/0
                                      </div>
                                    )}
                                  {todayWithdraw !== undefined &&
                                    allWithdraw !== undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        {todayWithdraw.amount}/
                                        {allWithdraw.amount}
                                      </div>
                                    )}

                                  <a class="report-count" href="#/AWithdrawl">
                                    {' '}
                                    view all
                                  </a>
                                </div>
                                <div class="inner-card-icon bg-warning">
                                <i class="icon-wallet menu-icon"></i>
                                </div>
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                    Today & Total Profit
                                  </span>
                                  {todayProfit === undefined &&
                                    allProfit === undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        0/0
                                      </div>
                                    )}
                                  {todayProfit !== undefined &&
                                    allProfit !== undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        {todayProfit.amount}/{allProfit.amount}
                                      </div>
                                    )}

                                  <span class="report-count"> view all</span>
                                </div>
                                <div class="inner-card-icon bg-primary">
                                  <i class="icon-trophy menu-icon"></i>
                                </div>
                              </div>
                            </div>
                            <div class="row report-inner-cards-wrapper">
                              <div class=" col-md -6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                    Withdrawal Request
                                  </span>
                                  {allWithdrawReq === undefined && (
                                    <div class="text-lg fw-bold  mybigdata">
                                      0
                                    </div>
                                  )}
                                  {allWithdrawReq !== undefined && (
                                    <div class="text-lg fw-bold  mybigdata">
                                      {allWithdrawReq.count}
                                    </div>
                                  )}

                                  <a class="report-count" href="#/AWithdraw"> view all</a>
                                </div>
                                <div class="inner-card-icon bg-success">
                                <i class="icon-book-open menu-icon"></i>

                                </div>
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                    Recharge Request
                                  </span>
                                  {allRechargeReq === undefined && (
                                    <div class="text-lg fw-bold  mybigdata">
                                      0
                                    </div>
                                  )}
                                  {allRechargeReq !== undefined && (
                                    <div class="text-lg fw-bold  mybigdata">
                                      {allRechargeReq.count}
                                    </div>
                                  )}

                                  <a class="report-count" href="#/ARecharge"> view all</a>
                                </div>
                                <div class="inner-card-icon bg-danger">
                                  <i class="icon-grid menu-icon"></i>
                                </div>
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                               
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                              
                            
                              </div>
                      
                           
                            </div>
                         

                          </div>
                        </div>
                      </div>
                    </div>
    </div>
  )
}

export default Report